<template>
  <dash-page :title="$t('Dashboard')"
             :subtitle="$t('Administrator')"
             icon="mdi-view-dashboard-outline"
             side-admin-manager >

    <template #default>

      <!--  Pre Header -->
      <payment-plan-notification class="mt-5" only-warning />

      <v-row class="my-3">
        <v-col  cols="12" xs="12" sm="12" md="12" lg="6" xl="6"  >
          <v-sheet dark class="wsRoundedHalf pa-6 fill-height" :color="wsDARK" style="background: linear-gradient(25deg, rgba(47, 84, 152, 0.76), rgba(157, 60, 0, 0.57))">
            <div class="d-flex align-center">
              <v-icon  class="mr-5">mdi-briefcase-outline</v-icon>
              <div>
                <h6 class="font-weight-light">{{ $t('Name') }}:</h6>
                <h5>{{ selectedBusiness.name }}</h5>
              </div>
            </div>
            <div class="d-flex mt-3 align-center">
              <v-icon class="mr-5">mdi-web</v-icon>

              <div>
                <h6 class="font-weight-light">{{ $t('PublicPage') }}:</h6>
                <a class="noUnderline white--text" target="_blank" :href="'https://' + (selectedBusiness.domain ? selectedBusiness.domain : ((is_westudy ? $store.state.second_hostname : $store.state.hostname ) + '/' + selectedBusiness.alias)) ">
                  <h5> {{ selectedBusiness.domain ? selectedBusiness.domain : ((is_westudy ? $store.state.second_hostname : $store.state.hostname ) + '/' + selectedBusiness.alias)    }} </h5>
                </a>

              </div>


            </div>
          </v-sheet>
        </v-col>
        <v-col  cols="12" xs="12" sm="12" md="12" lg="6" xl="6"  >
          <v-sheet dark class="wsRoundedHalf pa-6 fill-height d-flex align-center" :color="wsDARKLIGHT">
            <div>
              <div class="d-flex">
                <v-icon  class="mr-5">mdi-cash</v-icon>
                <div>
                  <h6 class="font-weight-light">{{ $t('BusinessPlan') }}</h6>
                  <h5>{{ selectedBusiness.business_plan_name }}</h5>
                </div>
              </div>
              <div class="d-flex mt-3">
                <v-icon class="mr-5">mdi-calendar</v-icon>
                <div>
                  <h6 class="font-weight-light">{{ $t('ActiveTill') }}</h6>
                  <h5>{{ selectedBusiness.business_plan_date_active}}</h5>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

<!--      <latest-tasks />-->

    </template>


  </dash-page>
</template>

<script>
import {mapActions,mapState} from "vuex";
import paymentPlanNotification from "@/components/pages/businessDashboard/UI/paymentPlanNotification.vue";
// import latestTasks from "@/components/pages/businessDashboard/dashBusiness/latestTasks";

export default {
  name: "dashBusinessManager",
  props: ['alias'],
  components : {
    paymentPlanNotification
    // latestTasks
  },
  data() {
    return {
      business : {}
    }
  },
  computed : {
    ...mapState( 'business', [ 'selectedBusiness' ] ),
  },
  methods : {
    ...mapActions( 'dashboard', [ 'GET_DASHBOARD_ADMIN' ] ),

    initPage() {
      this.GET_DASHBOARD_ADMIN().then((out)=>{
        if ( out.result ) {
          this.business = out.data
        }
        this.loading = false
      }).catch(()=>{this.loading = false})
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>