<template>
  <dash-page-new :title="selectedBusiness.name"
             :mobile-title="selectedBusiness.name"
             :root="$t('Dashboard')"
             icon="mdi-school-outline">

    <template #header_action.desktop>
      <v-btn
          :to="businessPublicLink('')"
          class="noCaps hoverUnderline"
          target="_blank"
          text
          :color="wsACCENT" >

        {{ selectedBusiness.domain ? selectedBusiness.domain : ((is_westudy ? $store.state.second_hostname : $store.state.hostname ) + '/' + selectedBusiness.alias) }}
        <v-icon class="ml-2">mdi-arrow-top-right-bold-box-outline</v-icon>
      </v-btn>
    </template>

      <wsNavigationHeader
          key="navigationStudent"
          v-model="navigation"
          :items="navigationItems"
          class="my-5  "
      />
      <div class="fill-height">

        <PageCoursesStudent v-if="navigation === 'myStudy'" />
        <PageCoursesStudentAll v-if="navigation === 'courses'" />
      </div>




  </dash-page-new>
</template>

<script>
import {mapState} from "vuex";
import PageCoursesStudent from "@/components/pages/westudy/student/PageCoursesStudent";
import PageCoursesStudentAll from "@/components/pages/westudy/student/PageCoursesStudentAll";
export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  components : {
    PageCoursesStudent,
    PageCoursesStudentAll
  },
  data() {
    return {
      navigation : 'myStudy',
    }
  },
  computed : {
    ...mapState( 'business', [ 'selectedBusiness' ] ),
    navigationItems() {
      return [
        { text : this.$t('MyStudy') , value : 'myStudy' },
        { text : this.$t('AllCourses') , value : 'courses' },
      ]
    },
  },
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>