<template>
  <dash-page :title="selectedBusiness.name"
             :subtitle="selectedBusiness.domain ? selectedBusiness.domain : ((is_westudy ? $store.state.second_hostname : $store.state.hostname ) + '/' + selectedBusiness.alias)  "
             icon="mdi-view-dashboard-outline"
             no-side
              >

    <template #header_action>
      <div class="wsRoundedHalf pa-3 px-6">
        <ws-chip :color="wsACCENT" icon="mdi-cash" bold :text="selectedBusiness.business_plan_name" ></ws-chip>
        <ws-chip :color="wsACCENT" icon="mdi-calendar" :text="selectedBusiness.business_plan_date_active" ></ws-chip>
      </div>
    </template>

    <template #default>

      <v-sheet dark class="wsRoundedHalf mt-3 py-3 px-6"
               :color="wsDARK" style="background: linear-gradient(25deg, rgba(47, 84, 152, 0.76), rgba(157, 60, 0, 0.57))">
        <v-row class="pa-0 ma-0">
          <v-col cols="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">Всього  курсів</h6>
            <h3 class="text-center">13</h3>
          </v-col>
          <v-col cols="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">Всього  студентів</h6>
            <h3 class="text-center">328</h3>
          </v-col>
          <v-col cols="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">Нових  заявок</h6>
            <h3 class="text-center">15</h3>
          </v-col>
          <v-col cols="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">Заявок  сьогодні</h6>
            <h3 class="text-center">+3</h3>
          </v-col>
          <v-col cols="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">Студентів сьогодні</h6>
            <h3 class="text-center">+3</h3>
          </v-col>
          <v-col cols="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">Всього  курсів</h6>
            <h3 class="text-center">3000</h3>
          </v-col>
        </v-row>
      </v-sheet>

      <v-row class="my-3">
        <v-col  cols="12" xs="12" sm="12" md="12" lg="6" xl="6"  >
          <v-sheet class="wsRoundedHalf pa-6 fill-height " >

            <div class="d-flex justify-space-between align-center">
              <h4 class="">Курси:</h4>
              <v-btn elevation="0" small  class="noCaps" outlined :color="wsACCENT">Всі курси</v-btn>
            </div>



            <v-simple-table class="mt-5" max-height="300" >
              <tbody>
              <tr>
                <td class="py-3">
                  <div class="d-flex">
                    <v-avatar :color="wsBACKGROUND"  size="32" class="mr-3">
                      <v-icon dark>mdi-school</v-icon>
                    </v-avatar>
                    <div>
                      <h5>Новітні технології в оствіті та багато чого цікавого</h5>
                      <h6 class="font-weight-light">Новітні технології в оствіті та багато чого цікавого</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <ws-chip icon="mdi-account-school" text="Студентів: 15"></ws-chip>
                </td>
              </tr>
              <tr>
                <td class="py-3">
                  <div class="d-flex">
                    <v-avatar :color="wsBACKGROUND"  size="32" class="mr-3">
                      <v-icon dark>mdi-school</v-icon>
                    </v-avatar>
                    <div>
                      <h5>Новітні технології в оствіті та багато чого цікавого</h5>
                      <h6 class="font-weight-light">Новітні технології в оствіті та багато чого цікавого</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <ws-chip icon="mdi-account-school" text="Студентів: 15"></ws-chip>
                </td>
              </tr>
              <tr>
                <td class="py-3">
                  <div class="d-flex">
                    <v-avatar :color="wsBACKGROUND"  size="32" class="mr-3">
                      <v-icon dark>mdi-school</v-icon>
                    </v-avatar>
                    <div>
                      <h5>Новітні технології в оствіті та багато чого цікавого</h5>
                      <h6 class="font-weight-light">Новітні технології в оствіті та багато чого цікавого</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <ws-chip icon="mdi-account-school" text="Студентів: 15"></ws-chip>
                </td>
              </tr>

              </tbody>
            </v-simple-table>

          </v-sheet>
        </v-col>
        <v-col  cols="12" xs="12" sm="12" md="12" lg="6" xl="6"  >
          <v-sheet class="wsRoundedHalf pa-6 fill-height" max-height="300" >

            <div class="d-flex justify-space-between align-center">
              <h4 class="">Студентів за місяць:</h4>
              <v-btn elevation="0" small  class="noCaps" outlined :color="wsACCENT">Всі курси</v-btn>
            </div>

            <v-sheet color="transparent" class="pt-6 ">
              <apexchart class="ml-n1" type="line" height="100%"  :options="chartOptions" :series="series"></apexchart>
            </v-sheet>

          </v-sheet>
        </v-col>
      </v-row>



    </template>


  </dash-page>
</template>

<script>
import {mapActions,mapState} from "vuex";
import apexchart from "vue-apexcharts";

export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  components : {
    apexchart
  },
  data() {
    return {
      business : {},
      dashobard : {},
      graphicValues : [7,9,12,7,9,12,12,7,9,12,12,4,6,12,7,9,12,12,4,6,1],
      graphicLabels : [4,6,12,4,6,12,4,6,12,4,6,12,4,6,12,4,6,12,4,6,12,4,6,12,4,6,12],
    }
  },
  computed : {
    ...mapState( 'business', [ 'selectedBusiness' ] ),
    series() {
      return [{
        name: "Нові реєстрації",
        data: this.graphicValues
      }]
    } ,
    chartOptions() {
      return {
        chart: {
          height: '100%',
          type: 'line',
          zoom: {
            enabled: false
          },
          floating : true,
          toolbar : {
            tools: {
              download: false
            }
          },
        },
        colors : [this.wsACCENT],
        dataLabels: {
          enabled: false
        },

        stroke: {
          curve: 'smooth',
          width : 3,
        },


        grid: {
          show : false,
        },
        yaxis : {
          show: false,
          floating : true,
          axisBorder : { show : false },
          axisTicks : { show : false },
        },
        xaxis: {
          axisBorder : { show : false },
          axisTicks : { show : false },
          floating : true,
          categories: this.graphicLabels,
        },
      }
    }
  },
  methods : {
    ...mapActions( 'dashboard', [ 'GET_DASHBOARD_ADMIN' ] ),

    initPage() {
      this.GET_DASHBOARD_ADMIN().then((out)=>{
        if ( out.result ) {
          this.business = out.data
        }
        this.loading = false
      }).catch(()=>{this.loading = false})
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>